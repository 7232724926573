<template>
  <div>
    <PreLoader v-if="isLoading" />
    <div class="reports-card">
      <a v-b-modal.ReportBrandsSales class="">
        <img src="@/assets/images/BrandsSales.svg" />{{
          $t("brands.ReportBrandsSales")
        }}
      </a>
    </div>

    <b-modal id="ReportBrandsSales" scrollable centered size="xl" hide-footer>
      <template #modal-title>
        <h3>
          <img src="@/assets/images/BrandsSales.svg" class="icon-lg" />
          {{ $t("brands.ReportBrandsSales") }}
        </h3>
      </template>

      <form autocomplete="off" method="post">
        <div class="row">
          <DatePicker
            :className="'col-md-6'"
            :id="'dateFrom'"
            :value="brands.filterData.dateFrom"
            v-on:changeValue="brands.filterData.dateFrom = $event"
            :title="$t('dateFrom')"
            :language="language"
          />
          <TimePicker
            :className="'col-md-6'"
            :value="brands.filterData.timeFrom"
            v-on:changeValue="brands.filterData.timeFrom = $event"
            :title="$t('timeFrom')"
            :language="language"
          />
          <DatePicker
            :className="'col-md-6'"
            :id="'dateTo'"
            :value="brands.filterData.dateTo"
            v-on:changeValue="brands.filterData.dateTo = $event"
            :title="$t('dateTo')"
            :language="language"
          />
          <TimePicker
            :className="'col-md-6'"
            :value="brands.filterData.timeTo"
            v-on:changeValue="brands.filterData.timeTo = $event"
            :title="$t('timeTo')"
            :language="language"
          />

          <CustomSelectBoxWithImg
            :className="'col-md-12'"
            :id="'userSellerToken'"
            :value="brands.filterData.userSellerToken"
            :options="userTokenOptions"
            v-on:changeValue="brands.filterData.userSellerToken = $event"
            :title="$t('users.selectSeller')"
            :imgName="'users.svg'"
          />
          <CustomSelectBoxWithImg
            :className="'col-md-12'"
            :id="'activityTypeToken'"
            :value="brands.filterData.activityTypeToken"
            :options="activityTypeTokenOptions"
            v-on:changeValue="activityTypeChanged($event)"
            :title="$t('activityTypes.select')"
            :imgName="'activity-types.svg'"
          />
          <CustomSelectBoxWithImg
            :className="'col-md-12'"
            :id="'brandToken'"
            :value="brands.filterData.brandToken"
            :options="brandTokenOptions"
            v-on:changeValue="brands.filterData.brandToken = $event"
            :title="$t('brands.select')"
            :imgName="'brand.svg'"
          />

          <CustomInput
            :className="'col-md-12'"
            :id="'sendTo'"
            :value="brands.filterData.sendTo"
            v-on:changeValue="brands.filterData.sendTo = $event"
            :title="$t('email')"
            :imgName="'email.svg'"
          />

          <CustomCheckbox
            :className="'col-md-4'"
            :value="brands.filterData.userSellerDateReportViewStatus"
            v-on:changeValue="userSellerDateReportViewStatusChanged($event)"
            :title="$t('brands.userSellerDateReportViewStatus')"
          />
          <CustomCheckbox
            :className="'col-md-4'"
            :value="brands.filterData.userClientDateReportViewStatus"
            v-on:changeValue="userClientDateReportViewStatusChanged($event)"
            :title="$t('brands.userClientDateReportViewStatus')"
          />
          <CustomCheckbox
            :className="'col-md-4'"
            :value="brands.filterData.qrSessionDateReportViewStatus"
            v-on:changeValue="qrSessionDateReportViewStatusChanged($event)"
            :title="$t('brands.qrSessionDateReportViewStatus')"
          />
        </div>

        <div class="form-footer">
          <button
            name="submit"
            type="submit"
            class="btn btn-submit"
            @click.prevent="getReportBrandsSales()"
          >
            {{ $t("viewAndSend") }}
          </button>
          <button
            name="submit"
            type="submit"
            class="btn btn-cancel"
            @click.prevent="$bvModal.hide('ReportBrandsSales')"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { STATUS } from "@/utils/constants";
import { USER_TYPE } from "@/utils/constantLists";
import PreLoader from "@/components/general/PreLoader.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import DatePicker from "@/components/general/DatePicker.vue";
import TimePicker from "@/components/general/TimePicker.vue";
import CustomSelectBoxWithImg from "@/components/general/CustomSelectBoxWithImg.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import Brands from "@/models/brands/Brands";
import Users from "@/models/users/Users";
import ActivityTypes from "@/models/activityTypes/ActivityTypes";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "ReportBrandsSales",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    CustomInput,
    DatePicker,
    TimePicker,
    CustomSelectBoxWithImg,
    CustomCheckbox,
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      brands: new Brands(),
      userTokenOptions: [],
      users: new Users(),
      activityTypes: new ActivityTypes(),
      activityTypeTokenOptions: [],
      brandsToFilter: new Brands(),
      brandTokenOptions: [],
    };
  },
  methods: {
    async getReportBrandsSales() {
      this.isLoading = true;
      try {
        let response = await this.brands.brand.getReportBrandsSales(
          this.language,
          this.userAuthorizeToken,
          this.brands.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("ReportBrandsSales");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getUsersDialog() {
      this.isLoading = true;
      try {
        this.users.filterData.userTypeToken = USER_TYPE.Seller;
        this.userTokenOptions = await this.users.user.getUsersDialog(
          this.language,
          this.userAuthorizeToken,
          this.users.filterData
        );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfActivityTypes() {
      this.isLoading = true;
      try {
        this.activityTypeTokenOptions =
          await this.activityTypes.activityType.getDialogOfActivityTypes(
            this.language,
            this.userAuthorizeToken
          );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getBrandDialog() {
      this.isLoading = true;
      try {
        this.brandTokenOptions = await this.brandsToFilter.brand.getBrandDialog(
          this.language,
          this.userAuthorizeToken,
          this.brandsToFilter.filterData
        );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    activityTypeChanged(activityTypeToken) {
      this.brands.filterData.activityTypeToken = activityTypeToken;
      this.brands.filterData.brandToken = "";
      this.brandsToFilter.filterData.activityTypeToken = activityTypeToken;
      this.getBrandDialog();
    },
    userSellerDateReportViewStatusChanged(status) {
      this.brands.filterData.userSellerDateReportViewStatus = status;
      if (status == false) {
        this.brands.filterData.userClientDateReportViewStatus = false;
        this.brands.filterData.qrSessionDateReportViewStatus = false;
      }
    },
    userClientDateReportViewStatusChanged(status) {
      this.brands.filterData.userClientDateReportViewStatus = status;
      if (status == true)
        this.brands.filterData.userSellerDateReportViewStatus = true;
      else this.brands.filterData.qrSessionDateReportViewStatus = false;
    },
    qrSessionDateReportViewStatusChanged(status) {
      this.brands.filterData.qrSessionDateReportViewStatus = status;
      if (status == true) {
        this.brands.filterData.userSellerDateReportViewStatus = true;
        this.brands.filterData.userClientDateReportViewStatus = true;
      }
    },
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  async created() {
    this.getUsersDialog();
    this.getDialogOfActivityTypes();
    this.getBrandDialog();
  },
};
</script>
