import PaginationData from "./../../models/general/PaginationData";
import Card from "./Card";
import CardsFilter from "./CardsFilter";

export default class Cards {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.cardsData = [];
    this.card = new Card();
    this.filterData = new CardsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.pagination);
    this.cardsData = data.cardsData;
  }
}
