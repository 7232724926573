<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <div class="reports-cards">
      <ReportCards
        v-if="checkPrivilege(usersPrivilegeData.usersPrivilegeCards.report)"
      />
      <ReportSellCards
        v-if="checkPrivilege(usersPrivilegeData.usersPrivilegeSellCards.report)"
      />
      <ReportBrands
        v-if="checkPrivilege(usersPrivilegeData.usersPrivilegeBrands.report)"
      />
      <ReportBrandsSales
        v-if="
          checkPrivilege(
            usersPrivilegeData.usersPrivilegeQrCodeSessions
              .qrCodeStatisticsReport
          )
        "
      />
    </div>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import { mapGetters } from "vuex";
import { checkPrivilege } from "@/utils/functions";
import ReportCards from "@/components/reports/ReportCards.vue";
import ReportSellCards from "@/components/reports/ReportSellCards.vue";
import ReportBrands from "@/components/reports/ReportBrands.vue";
import ReportBrandsSales from "@/components/reports/ReportBrandsSales.vue";

export default {
  name: "Reports",
  components: {
    PreLoader,
    ReportCards,
    ReportSellCards,
    ReportBrands,
    ReportBrandsSales,
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  methods: {
    checkPrivilege,
  },
  computed: {
    ...mapGetters(["usersPrivilegeData"]),
  },
  async created() {},
};
</script>
